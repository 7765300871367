<template>
  <div class="profile">
    <div class="main-header">
      <dashboard-header page="Contacts"></dashboard-header>
    </div>
      <div class="header">
        <Header/>
      </div>
      <div class="bottom">
        <div class="entry">
            <Entry :entries="entries" v-on:show-data="showData"/>
        </div>
        <div class="appointment" v-if="showDetails">
            <Details :user="user" v-on:dismiss="dismissUser"/>
        </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import DashboardHeader from '@/components/DashboardHeader.vue'
import Entry from './components/Entry.vue'
import Details from './components/Details.vue'

export default {
  components: {
    Header,
    DashboardHeader,
    Entry,
    Details
  },
  data() {
    return {
      entries: [
          { img: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Nonso Godfrey", tagName: "nonsogodgrey", time: "2:24PM", status: "pending", role: 'Staff'},
          { img: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Jack Dorsey", tagName: "jack", time: "3:00PM", status: "in", role: 'Guest '},
          { 
              img: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60', 
              name: "Elon Musk", tagName: "elonmusk", time: "4:24PM", status: "out", role: 'Staff'
          }
      ],
      showDetails: false,
      user: {}
    }
  },
  methods: {
      showData(user) {
          this.user = user
          this.showDetails = true
      },
      dismissUser(tagName) {
          this.entries = this.entries.filter(user => user.tagName !== tagName)
          this.showDetails = false
          console.log(tagName)
      }
  }
}
</script>

<style scoped>
  .profile {
    padding: 0 10px;
  }

  .header {
    margin-top: 4rem;
  }

  .entry {
    padding: 0 10px;
  }

  .bottom {
    display: flex;
    margin-bottom: 2em;
  }

  .bottom > div:first-child {
    flex: 4;
  }

  .appointment {
      flex: 2;
  }
</style>